<template>
    <v-main style="background-color: black; padding-top: 0; z-index: 10000">
        <div style="width: 427px; height: 240px; background-color: white; position: absolute; overflow-x2: hidden; overflow-y2: auto" v-html="randomHtml" ref="container" id="el-0">
        </div>
        <div class="d-flex" style="margin-top: 240px">
            <v-btn @click="generateRandomHTML" class="mt-2 ml-2">
                <v-icon>mdi-refresh</v-icon>
                Refresh
            </v-btn>
            <v-btn @click="download" class="mt-2 ml-2">
                <v-icon>mdi-download</v-icon>
                Download
            </v-btn>
            <v-btn @click="reset" class="mt-2 ml-2">
                <v-icon>mdi-refresh</v-icon>
                Reset
            </v-btn>
        </div>
        <pre style="color: white" v-if="false">{{ JSON.stringify(entries, null, 2) }}</pre>
        <div v-if="false">
            <svg width="500" height="500" viewBox="0 0 100 100">
                <path style="fill: #000; fill-opacity: 0.8;"
                      d="M50 50
                       m-50, 0
                       a 50,50 0 0,1 50,-50
                       v 30
                       a 20,20 0 0,0 -20,20">
                </path>
            </svg>

            <svg width="500" height="500" viewBox="0 0 100 100">
                <path style="fill: #000; fill-opacity: 0.8;"
                      d="M50 50
                       m 0, -50
                       a 50,50 0 0,1 50,50
                       h-20
                       a 30,30 0 0,0 -30,-30">
                </path>
            </svg>

            <svg width="500" height="500" viewBox="0 0 100 100">
                <path style="fill: #000; fill-opacity: 0.8;"
                      d="M50 50
                       m 50, 0
                       a 50,50 0 0,1 -50,50
                       v-20
                       a 30,30 0 0,0 30,-30">
                </path>
            </svg>

            <svg width="500" height="500" viewBox="0 0 100 100">
                <path style="fill: #000; fill-opacity: 0.8;"
                      d="M50 50
                       m 0, 50
                       a 50,50 0 0,1 -50,-50
                       h20
                       a 30,30 0 0,0 30,30">
                </path>
            </svg>

            <svg width="500" height="500" viewBox="0 0 100 100">
                <path style="fill: #000; fill-opacity: 0.8;"
                      d="M50 50
                       m-50, 0
                       a 50,50 0 0,1 50,-50
                       v 20
                       a 30,30 0 0,0 -30,30">
                </path>
                <path style="fill: #000; fill-opacity: 0.8;"
                      d="M50 50
                       m 0, -50
                       a 50,50 0 0,1 50,50
                       h-20
                       a 30,30 0 0,0 -30,-30">
                </path>
                <path style="fill: #000; fill-opacity: 0.8;"
                      d="M50 50
                       m 50, 0
                       a 50,50 0 0,1 -50,50
                       v-20
                       a 30,30 0 0,0 30,-30">
                </path>
                <path style="fill: #000; fill-opacity: 0.8;"
                      d="M50 50
                       m 0, 50
                       a 50,50 0 0,1 -50,-50
                       h20
                       a 30,30 0 0,0 30,30">
                </path>
            </svg>


        </div>
    </v-main>
</template>

<script>
import Vue from "vue";
import socialLoginOutdated from "@/components/SocialLoginOutdated.vue";

export default {
    name: "Asdf",
    data: () => ({
        parameters: {
            hasChild: 0.7,
            hasSibling: 0.3,
            hasWidth: 0.5,
            hasHeight: 0.5,
            hasOneMargin: 0.2,
            hasOnePadding: 0.2,
            maximumDepth: 10,
            maxElements: 30,
            iterations: 1000
        },
        currentHue: 0,
        currentId: 1,
        randomHtml: "",
        entries: []
    }),
    created() {
        setInterval(() => {
            //this.randomHtml = this.generateRandomHTML();
        }, 500);
        //this.loop();
    },
    methods: {
        loop() {
            this.generateRandomHTML();
            requestAnimationFrame(this.loop);
        },
        generateRandomHTML() {
            this.currentHue = 0;
            this.currentId = 1;
            let element = document.createElement("div");
            const remainingDepth = this.parameters.maximumDepth;
            this.generateRandomDOM(element, remainingDepth, this.parameters);
            this.randomHtml = element.outerHTML;

            Vue.nextTick(() => {
                this.processEntry(this.randomHtml);
            });
        },
        generateRandomDOM(element, remainingDepth, params) {
            element.style.backgroundColor = `hsl(${this.currentHue % 360}, 100%, 50%)`;
            this.currentHue += 20;
            element.id = "el-" + this.currentId++;
            if (Math.random() < params.hasWidth) {
                element.style.width = `${Math.floor(Math.random() * 200) + 10}${this.randomElement(["px", "%"])}`;
            }
            if (Math.random() < params.hasHeight) {
                element.style.height = `${Math.floor(Math.random() * 200) + 10}${this.randomElement(["px", "%"])}`;
            }
            if (Math.random() < params.hasOneMargin) {
                //element.style.marginTop = `${Math.floor(Math.random() * 20) + 1}px`;
            }
            if (Math.random() < params.hasOneMargin) {
                //element.style.marginBottom = `${Math.floor(Math.random() * 20) + 1}px`;
            }
            if (Math.random() < params.hasOneMargin) {
                element.style.marginLeft = `${Math.floor(Math.random() * 20) + 1}px`;
            }
            if (Math.random() < params.hasOneMargin) {
                element.style.marginRight = `${Math.floor(Math.random() * 20) + 1}px`;
            }
            if (Math.random() < params.hasOnePadding) {
                element.style.paddingTop = `${Math.floor(Math.random() * 20) + 1}px`;
            }
            if (Math.random() < params.hasOnePadding) {
                element.style.paddingBottom = `${Math.floor(Math.random() * 20) + 1}px`;
            }
            if (Math.random() < params.hasOnePadding) {
                element.style.paddingLeft = `${Math.floor(Math.random() * 20) + 1}px`;
            }
            if (Math.random() < params.hasOnePadding) {
                element.style.paddingRight = `${Math.floor(Math.random() * 20) + 1}px`;
            }
            if (--remainingDepth < 0) {
                return element;
            }
            if (Math.random() < params.hasChild && this.currentId <= params.maxElements) {
                const child = document.createElement("div");
                element.appendChild(child);
                this.generateRandomDOM(child, remainingDepth, params);
                while (Math.random() < params.hasSibling && this.currentId <= params.maxElements) {
                    const sibling = document.createElement("div");
                    element.appendChild(sibling);
                    this.generateRandomDOM(sibling, remainingDepth, params);
                }
            }

            return element;
        },
        randomElement(array) {
            return array[Math.floor(Math.random() * array.length)];
        },
        processEntry(html) {
            let element = this.$refs.container.firstChild;
            const propertyList = [];
            this.extractProperties(element, propertyList);
            let firstEntry = propertyList[0];
            if (firstEntry.width > 0 && firstEntry.height > 0) {
                this.entries.push({
                    html: html,
                    properties: propertyList
                });
                //console.log(html)
                //console.log(propertyList)
            }
            requestAnimationFrame(() => {
                if (this.entries.length < this.parameters.iterations) {
                    this.generateRandomHTML(html);
                } else {
                    console.log("done");
                }
            });
        },
        extractProperties(element, propertyList) {
            const rect = element.getBoundingClientRect();
            const properties = {
                x: rect.x,
                y: rect.y,
                width: rect.width,
                height: rect.height,
            };
            propertyList.push(properties);
            for (let i = 0; i < element.children.length; i++) {
                this.extractProperties(element.children[i], propertyList);
            }
        },
        download() {
            const blob = new Blob([JSON.stringify(this.entries)], {type: "application/json"});
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = "data.json";
            link.click();
        },
        reset() {
            this.entries = [];
            this.randomHtml = "";
        }
    }
};
</script>

<style scoped>

</style>